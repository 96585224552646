import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import Layout from '../../components/layout'
import { flatten } from '../../utils/data'

const SitemapPage = ({ data: { sitemap } }) => {
  const page = useMemo(() => flatten(sitemap || {}, ['hero']), [sitemap])
  return (
    <Layout pageTags={page.seoMetaTags} noIndex={page.noIndex} headerTransition>
      <section></section>
    </Layout>
  )
}

SitemapPage.propTypes = {
  data: PropTypes.shape({
    sitemap: PropTypes.object.isRequired,
  }),
}

export default SitemapPage

export const query = graphql`
  query SitemapPage {
    sitemap: datoCmsParentSitemap {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      noIndex
    }
  }
`
