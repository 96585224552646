import has from 'lodash/has'
import get from 'lodash/get'
import compact from 'lodash/compact'

// function to flatten data queried from CMS.
// Some fields are meant to be singular but due to
// DatoCMS data modelling, there is a need for a compromise.
export function flatten(payload, fields = []) {
  const mutable = { ...payload }
  fields.forEach((field) => {
    mutable[field] = [].concat(mutable[field]).shift()
  })
  return mutable
}

// function to shorthand retrieve html string from a text node
// if string is passed in then just return the string
// otherwise extract html from nested object.
export const getHtml = (node) => {
  if (has(node, 'childMarkdownRemark.html')) return get(node, 'childMarkdownRemark.html')
  return node
}

// function to split html content into sections using horizontal rules
export const splitHtml = (html) => compact(html.split(/\n?<hr>\n?/gim))
